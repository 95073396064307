/**
  * Each instance of this class represents and
  * handles a feature image showcase container
  * @param {HTMLElement} root - root element of container
*/

const FeatureShowcase = class {
  root = null
  observer = null
  ioOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1 //this.buildThresholdList()
  }

  constructor(root) {
    this.root = root
    this.setupObserver()
  }

  setupObserver() {
    this.observer = new IntersectionObserver(this.setActiveBlock, this.ioOptions)
    this.root[0].querySelectorAll('.fs-block').forEach((el) => {
      this.observer.observe(el)
    })
  }

  // buildThresholdList() {
  //   let thresholds = [];
  //   let numSteps = 20;

  //   for (let i = 1.0; i <= numSteps; i++) {
  //     let ratio = i / numSteps;
  //     thresholds.push(ratio);
  //   }

  //   thresholds.push(0);
  //   return thresholds;
  // }

  /**
   * add's an 'active' class to a block that comes in view and
   * show's its corresponding image
   * @param {Array} entries - collection of all the elements
  */
  setActiveBlock = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.reset();
        entry.target.classList.add('active')
        this.showImage(entry.target.dataset.target)
      }
    })
  }

  /**
   * show's image with matching name attribute
   * @param {String} target - element attribute value
  */
  showImage(target) {
    document.querySelector(`img[data-name="${target}"]`).style.opacity = 1
  }

  /**
   * removes 'active' class and hides the image
   * for blocks that is no more in view
  */
  reset() {
    document.querySelector('.fs-block.active').classList.remove('active')
    document.querySelectorAll('.features-illustration-wrapper img').forEach(img => img.style.opacity = 0)
  }

  static init(root) {
    if (window.outerWidth <= 1199) return
    let fs = new FeatureShowcase(root)
  }
}

export default FeatureShowcase