/**
 * Each instance of this class represents a Modal
 *
 * @param {HTMLElement} el - Represents NodeList of btn elements for modal
 *
 */
export default Modal = class {

    constructor(el) {
        this.element = el

        Array.from(el, e => {
            e.addEventListener('click', this.handleClick)
        })

        // dismiss modal
        this.dismissModal()
    }

    /**
     * Click Event handler
     * @param {Event} event
     */
    handleClick = event => {
        event.preventDefault();

        let currentTarget = event.currentTarget
        let targetModal = document.querySelector(`.modal#${currentTarget.dataset.target}`)
        this.showModal(targetModal, event)
    }

    /**
     * @param {HTMLElement} videoEl
    */
    autoPlayVideo = videoEl => {
        videoEl.play();
    }

    /**
     * @param {HTMLElement} targetModal - Current modal element
     * @param {Event} event
     */
    showModal = (targetModal, event) => {
        if (targetModal) {
            const hasVideoEl = targetModal.querySelectorAll('.modal-content video').length
            document.body.classList.add('modal-open')
            targetModal.classList.add('show')

            // check if modal body contains a video el.
            // if yes it autoplay's the video
            if (hasVideoEl) {
                const videoEl = targetModal.querySelector('.modal-content video');
                setTimeout(() => {
                    this.autoPlayVideo(videoEl);
                }, 1000)
            }
        } else {
            console.error(`Modal with id="${event.currentTarget.dataset.target}" not found!`)
        }
    }

    dismissModal = () => {
        document.querySelectorAll("button[data-dismiss='modal']").forEach(btn => {
            btn.addEventListener('click', e => {
                this.checkAndResetMedia(e)
                document.body.classList.remove('modal-open')
                e.currentTarget.closest('.modal.show').classList.remove('show')
            })
        })
    }

    /**
     * Click Event handler
     * @param {Event} e
     * checks if modal body contains a iframe with youtube embed
     * - if Yes then reset the src attribute when modal is closed as youtube embed
     *   tend to keep playing video even when modal is closed
     */
    checkAndResetMedia = e => {
        const nextElement = e.currentTarget.nextElementSibling;
        const nextElementTagName = e.currentTarget.nextElementSibling.tagName;

        if (nextElement && nextElementTagName === "IFRAME" && nextElement.src.includes('https://www.youtube.com')) {
            nextElement.src = nextElement.src
        } else if (nextElementTagName === 'VIDEO') {
            nextElement.pause();
        }
    }

    /**
     * Initializes the Modal component and related functionalities
     * @param {HTMLElement} el
     */
    static init(el) {
        let m = new Modal(el)
    }
}
