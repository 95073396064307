// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import LazyLoad from "vanilla-lazyload";
import "./common/generic";
import "./common/navbar";
import Modal from "./common/modal";
import FeatureShowcase from "./common/feature_showcase";
import SlideIn from "./common/slide_in";
import "./common/carousel_flip";

var lazyLoadInstance = new LazyLoad();

document.addEventListener("DOMContentLoaded", () => {
  let el = document.querySelectorAll('button[data-toggle="modal"]');

  if (!el.length) return;

  Modal.init(el);
});

if (document.querySelectorAll("div[data-focus]").length) {
  import("./pages/home");
}

if (document.querySelector(".partners-swiper")) {
  import("./pages/partners");
}

document.addEventListener("DOMContentLoaded", () => {
  let el = document.querySelectorAll(".feature-showcase");
  if (!el.length) return;

  FeatureShowcase.init(el);
});

document.addEventListener("DOMContentLoaded", () => {
  let el = document.querySelectorAll("div[data-slide-in]");
  if (!el.length) return;

  SlideIn.init(el);
});
