const invertNavbar = () => {
  const mainEl = document.querySelector('main#main');
  const navEl = document.querySelector('nav.navbar');
  /**
   * add background classes for which navbar 
   * font color should be inverted
  */
  const darkBackgroundClasses = ["bg-gradient-deep-blue", "bg-[#062E44]"];

  darkBackgroundClasses.forEach(cls => {
    if (mainEl.firstElementChild.classList.contains(cls)) {
      navEl.classList.add('invert-nav');
    }
  })
}

document.addEventListener('DOMContentLoaded', () => { 
  invertNavbar();
})