const home = () => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: '0.2'
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('in');
      }
    })
  }, options)

  const targets = document.querySelectorAll('div[data-focus="in"]');
  targets.forEach(target => {
    observer.observe(target);
  })
}
home();