const flippingCardsCarousel = () => {
  const carouselEl = document.querySelector('.flipping-cards-carousel');
  if (!carouselEl) return

  const carouselItems = carouselEl.querySelectorAll('.card');

  setInterval(() => {
    Array.from(carouselItems).forEach(card => {
    if (card.classList.contains('slide__front')) {
      card.classList.remove('slide__front');
      card.classList.add('slide__back');
    } else if (card.classList.contains('slide__back')) {
      card.classList.remove('slide__back');
      card.classList.add('slide__front');
    }
  });
  }, 3000);
}

setTimeout(flippingCardsCarousel(), 3000)