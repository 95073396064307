/**
 * 
 * @param {HTMLAllCollection} root
 * Each instance of this class handles 
 * horizontal sliding in behaviour
 */

const SlideIn = class { 
  root = null
  iOptions = {
    threshold: this.buildThresholdList()
  }
  
  constructor(root) { 
    this.root = root
    this.setupObserver()
  }

  setupObserver = () => { 
    const observer = new IntersectionObserver(this.fireWhenInView, this.iOptions)
    Array.from(this.root).forEach(el => { 
      observer.observe(el);
    })
  }

  /**
   * 
   * @param {Array} entries 
   * @param {*} observer
   * Gets us the intersecting ratio for each element that comes in view 
   * and initial transformX value set for element
   */
  fireWhenInView = (entries, observer) => { 
    entries.forEach(entry => { 
      if (entry.isIntersecting) { 
        let slideInFrom = Number(entry.target.dataset.slideIn)
        let iRatioFixed = entry.intersectionRatio.toFixed(1)
        this.handleScroll(entry, slideInFrom, iRatioFixed)
      }
    })
  }

  /**
   * 
   * @param {HTMLElement} el 
   * @param {Number} initialSlideInVal 
   * @param {Number} iRatio
   * adds/updates transform values dynamically for 
   * element as it is scrolled in/out of view 
   */
  handleScroll = (el, initialSlideInVal, iRatio) => {
    document.addEventListener('scroll', () => {
      let absInitialSlideVal = Math.abs(initialSlideInVal)
      if (initialSlideInVal < 0) {
        el.target.style.transform = `translate3d(${initialSlideInVal + (iRatio * absInitialSlideVal)}%, 0px, 0px)`;
      } else { 
        el.target.style.transform = `translate3d(${initialSlideInVal - (iRatio * absInitialSlideVal)}%, 0px, 0px)`;
      }
    })
  }

  buildThresholdList() {
    let thresholds = [];
    let numSteps = 20;

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i / numSteps;
      thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
  }

  static init(root) {
    if (window.outerWidth <= 1199) return
    let s = new SlideIn(root)
  }
}

export default SlideIn
