/**
 * add white background to navbar when window is scrolled
 * update navbar logo color when navbar background is changed
 */

const invertNavbaroNScroll = () => {
    const navbar = document.querySelector("nav.navbar"),
    logoColorDark = "#204888",
    logoColorLight = "#ffffff"

    document.addEventListener('scroll', () => {
        if(window.scrollY > 0) {
            navbar.classList.add("bg-white")
            if (navbar.classList.contains("invert-nav")) {
                invertLogoColor(logoColorDark)
            }
        } else {
            navbar.classList.remove("bg-white")
            if (navbar.classList.contains("invert-nav")) {
                invertLogoColor(logoColorLight)
            }
        }
    })
}

/**
 * invert navbar logo svg path fill value
 * @param {String} color
 */
const invertLogoColor = (color) => {
    let logo = document.querySelectorAll(".navbar a.logo svg path")
    Array.from(logo).forEach((path, index) => {
        // update path fill values except last path
        if (index <= Array.from(logo).length - 2) {
            path.attributes.fill.value = color
        }
    })
}

/**
 * toggle navbar for sm, md screen widths
 */
const toggleNavbar = () => {
    const btnToggle = document.querySelector("#btn-navbar-toggle"),
    navbarMenu = document.querySelector("nav.navbar .navbar-list")

    btnToggle.addEventListener("click", () => {
        navbarMenu.classList.toggle("open");
        document.querySelector("nav.navbar").classList.toggle("toggled");
    })
}

document.addEventListener('DOMContentLoaded', () => {
    invertNavbaroNScroll();
    toggleNavbar();
})