import Swiper from "swiper/bundle";

const swiper = new Swiper(".partners-swiper", {
  slidesPerView: "auto",
  spaceBetween: 30,
  speed: 900,
  loop: true,
  autoplay: {
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".btn-next",
  },
})

export default swiper;
